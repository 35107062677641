/* eslint-disable max-len */
/* eslint-disable max-classes-per-file */

class ErrorGenerator extends Error {
  constructor({ message, status = 500, statusText }) {
    super();
    // Error.captureStackTrace(this, this.constructor);

    this.message = message || 'Non specified Error';

    this.status = status;

    this.statusText = statusText;
  }
}

export default ErrorGenerator;
