const arrayMapper = (array, key) => array.reduce((acc, entry) => {
  acc[entry[key]] = entry;
  return acc;
}, {});

const arrayMapperCumulate = (array, key, { from, to }) => array.reduce((acc, entry) => {
  acc[entry[key]] = {
    ...entry,
    [to]: [...((acc[entry[key]] && acc[entry[key]][to]) || []), entry[from]],
  };
  return acc;
}, {});

const responseMapper = (array, key) => array
  .filter((replies) => !replies.error)
  .map((reply) => arrayMapper(reply, key))
  .reduce((acc, res) => {
    Object.values(res).forEach((r) => { acc[r[key]] = r; });
    return acc;
  }, {});

module.exports = {
  arrayMapper,
  arrayMapperCumulate,
  responseMapper,
};
