import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getterAuthenticate: 'TheAuthenticate/myAuthenticate',
    }),
  },
  methods: {
    isActionVisible(right) {
      const { rights } = this.getterAuthenticate;
      return !((!rights || !rights[right]));
    },
  },
};
